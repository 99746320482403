import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Row from "./Row"
import tw from "twin.macro"
import styled from "styled-components"
import useDarkMode from "../../utils/useDarkMode"

const Wrapper = styled.nav`
    ${tw`fixed inset-x-auto top-0 mx-auto w-full py-4`};
    z-index: 999;
`

const Logo = styled.img`
    // ${tw`top-0 mt-12 lg:mt-0 lg:mb-16 fixed lg:block`};
`

const Header = ({ siteTitle }) => {
    const prefersDark = useDarkMode()
    return (
        <Wrapper>
            <Row>
                {prefersDark ? (
                    <Link to="/">
                        <Logo
                            src={require("../../images/logo/full/dark.svg")}
                            alt="Sidebrain Logo"
                        />
                    </Link>
                ) : (
                    <Link to="/">
                        <Logo
                            src={require("../../images/logo/full/day.svg")}
                            alt="Sidebrain Logo"
                        />
                    </Link>
                )}
            </Row>
        </Wrapper>
    )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
