import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const Wrapper = styled.div`
    ${tw`mx-auto flex flex-wrap items-center`};
    max-width: calc(100vw - (100vw / 6));
`

const Row = (props) => {
    return <Wrapper {...props}>{props.children}</Wrapper>
}

export default Row
