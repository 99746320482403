import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import Row from "./Row"

import { Link } from "gatsby"

const Wrapper = styled.footer`
    ${tw`w-screen bg-black dark:bg-gray-800 pt-16 pb-12 -mt-12`}
`

const SocialLink = styled.a`
    ${tw`no-underline text-gray-300`};
`

const StyledLink = styled(Link)`
    a {
        ${tw`text-gray-300`}
    }
`

const Footer = (props) => {
    return (
        <Wrapper>
            <Row css={tw`pt-8 text-center md:text-left justify-between`}>
                <img
                    src={require("../../images/logo/full/dark.svg")}
                    alt="sidebrain logo"
                />

                <div
                    css={tw`flex items-center justify-between w-full md:w-1/6`}>
                    <SocialLink
                        href="https://www.linkedin.com/company/sidebrain"
                        target="_blank">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            class="feather feather-linkedin">
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect x="2" y="9" width="4" height="12"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                        </svg>
                    </SocialLink>
                    <SocialLink
                        href="https://instagram.com/getsidebrain"
                        target="_blank">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            class="feather feather-instagram">
                            <rect
                                x="2"
                                y="2"
                                width="20"
                                height="20"
                                rx="5"
                                ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                            <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                        </svg>
                    </SocialLink>
                    <SocialLink
                        href="https://twitter.com/getsidebrain"
                        target="_blank">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            class="feather feather-twitter">
                            <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                        </svg>
                    </SocialLink>
                </div>
            </Row>
            <Row
                css={tw`justify-between mt-8 flex-col md:flex-row text-center md:text-left`}>
                <div>
                    <small css={tw`dark:text-gray-300 text-gray-600`}>
                        &copy; 2020 | Sidebrain
                    </small>
                </div>

                <div
                    css={tw`flex flex-col md:flex-row items-center space-x-0 md:space-x-8 items-center w-full md:w-auto text-center md:text-right`}>
                    <StyledLink
                        css={tw`text-gray-300 hover:opacity-50 cursor-pointer text-sm`}
                        to="/privacy">
                        Privacy
                    </StyledLink>
                    {/* <StyledLink
                        css={tw`text-gray-300 hover:opacity-50 cursor-pointer text-sm`}
                        to="/terms">
                        Terms
                    </StyledLink> */}
                    <p
                        onClick={() =>
                            typeof window !== undefined &&
                            window.Metomic("ConsentManager:show")
                        }
                        css={tw`text-gray-300 hover:opacity-50 cursor-pointer text-sm mb-0`}>
                        Manage Cookies
                    </p>
                </div>
            </Row>
        </Wrapper>
    )
}

export default Footer
