/**
 * Custom hook for using Dark Mode;
 */

import { useState, useEffect } from "react"

export default function useDarkMode() {
    
    const [prefersDarkMode, setPrefersDarkMode] = useState(null)

    useEffect(() => {
        let prefersDark = window.matchMedia("(prefers-color-scheme: dark)")
        // alert(`prefers dark : ${prefersDark.matches}`)
        setPrefersDarkMode(prefersDark.matches)
    }, [])

    return prefersDarkMode
}
