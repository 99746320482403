/**
 * Main component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import tw from "twin.macro"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { ConsentGate, MetomicProvider } from "@metomic/react"

import "./Main.css"
import "../../fonts/fonts.css"

const StyledMain = styled.main`
    ${tw`dark:bg-black bg-transparent`};
`

const Main = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    // useEffect(() => {
    //     let prefersDark = window.matchMedia("(prefers-color-scheme: dark)")
    //     alert(`prefers dark : ${prefersDark.matches}`)
    // }, [window])

    return (
        <>
            {/* <Header siteTitle={"Sidebrain"} /> */}
            <link
                rel="stylesheet"
                href="https://use.typekit.net/swt2duq.css"></link>
            <MetomicProvider projectId="prj:b27287ca-5cac-4d1e-8982-2184d816670b">
                <ConsentGate micropolicy="statistics-2">
                    <Helmet>
                        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
                        <script
                            async
                            src="https://www.googletagmanager.com/gtag/js?id=UA-175449931-1"></script>
                        <script>
                            {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', 'UA-175449931-1');
                        `}
                        </script>
                    </Helmet>
                </ConsentGate>
                <StyledMain>{children}</StyledMain>
            </MetomicProvider>
        </>
    )
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Main
